import React from "react";
import {useEffect, useState} from 'react';
import NotificationAlert from "react-notification-alert";
import DataTable from 'react-data-table-component';
//var PlayerData= require ("../data/PlayerData.json");
// react-bootstrap components
import {
  Alert,
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form,
  Modal
} from "react-bootstrap";

function OffList() {
  const [PlayerData, setPlayerData] = useState({dataArray:{}});
  const [onlinePlayerArray,setonlinePlayerArray] = useState(Array());
  const [offlinePlayer24Array,setofflinePlayer24Array] = useState(Array());
  const [offlinePlayer24SArray,setofflinePlayer24SArray] = useState(Array());
  const [offlinePlayerArray,setofflinePlayerArray] = useState(Array());
  const [offlinePlayerFilArray,setofflinePlayerFilArray]= useState(Array());
  const [imVersandArray,setimVersandArray]= useState(Array());
  const [checkedPlayers,setcheckedPlayers] = useState(Array());
  const [showModal, setShowModal] = React.useState(false);
  const [currentPlayer,setCurrentPlayer] = useState(0);
  const [currentMessage,setCurrentMessage] = useState('');
  const [loginState,setLoginState]= useState({isLoggedin:false,passIt:""});
  const notificationAlertRef = React.useRef(null);
  const [selectedRows, setSelectedRows] = React.useState(Array());
  const [toggledClearRows, setToggleClearRows] = React.useState(false);


  
 var selectedCountGlobal = 0;
  var Sno=1;
  const handleLogin = (event) => {
    event.preventDefault();
    if(loginState.passIt=="hpnx1337"){
      setLoginState({
        isLoggedin:true,
      
      });
      window.localStorage.setItem('isLoggedin', 'true');
    }
    

  }
  const getData= async ()=>{
    await fetch('./data/PlayerData.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'mode': '*cors',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
       }
    }
    )
      .then(function(response){
        return response.json();
      }).then((json)=>{
        setPlayerData(json);
      });
  }
  useEffect(()=>{
    getData();
  },[]);
  useEffect(()=>{
    loadPlayers();
  },[PlayerData]);
  useEffect(()=>{
    loadCheckedPlayers();
  },[]);

  async function loadCheckedPlayers(){
    console.log("loadchecked Players called");
    //await fetch('JsonBank.json'
    await fetch('https://www.4dboards.de/php/getJsonBank.php'
    ,{
      headers : { 
        'Accept': '*/*',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Access-Control-Allow-Origin': '*',
        'Pragma': 'no-cache',
        'Expires': 0,
        'mode': '*cors',
       },
    }
    )
      .then(function(response){
       
        return response.json();
      }).then((json)=>{
        console.log("setting checked players at Jsonbank anfrage");
        console.log(json);
        setcheckedPlayers(json);
//        console.log("checked players");
 //       console.log(checkedPlayers['Players']);
      });
  }
  async function deleteCheckedPlayers(PlayerID){
    await fetch('https://www.4dboards.de/php/deleteJsonBank.php?PlayerID='+PlayerID
    ,{
      headers : { 
        'Accept': '*/*',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
       },
    }
    )
      .then(function(response){
        return response.json();
      }).then((json)=>{
        console.log(json);        
      });
  }
  const columns = [
    
    {
        name: 'Customer ID',
        selector: row => row.CustomerID,
        sortable: true,
    },
    {
      name: 'Player ID',
      selector: row => row.PlayerID,
      sortable:true,
    },
   {
      name: 'Name',
      selector: row => row.Name,
      sortable:true,
    },
    {
      name: 'offline Seit',
      selector: row => row.OfflineSince,
      sortable: true,
    },
    
];
var offlinePlayer24Array1=Array();
var offlinePlayer24SArray1=Array();
var offlinePlayerArray1=Array();
var onlinePlayerArray1= Array();
var imVersandArray1= Array();
var offlinePlayerFilArray1= Array();
 async function loadPlayers(){
    offlinePlayer24Array1=[]; 
    offlinePlayer24SArray1=[]; 
    offlinePlayerFilArray1=[];
    offlinePlayerArray1=[];
    if(Array.isArray(PlayerData['dataArray']) & PlayerData['dataArray']!== null){
      var customersMix = PlayerData['dataArray'];
    customersMix.forEach(function(customer){
      var playersArray = customer['Players'];
      if(playersArray['Player'] !== undefined){
        var players= playersArray['Player'];
        if(Array.isArray(players)){
          players.forEach((player)=>{
            var Betriebsstatus="";
            var BetriebstatusActive = "unknown";
            if(player['Properties'] !== undefined && player['Properties']['Property'] !== undefined){
              var playerProperties = player['Properties']['Property'];
              
              if(Array.isArray(playerProperties)){
                playerProperties.forEach((property)=>{
                  if(property['Name']==='Betriebsstatus')
                  {
                    Betriebsstatus = property['Value'];
                  }
                  if(property['Name']==='BetriebstatusActive')
                  {
                    BetriebstatusActive = property['Value'];
                  }
                });
              }
              else {
                
                if(playerProperties['Name']==='Betriebsstatus')
                {
                  Betriebsstatus = playerProperties['Value'];
                }
                if(playerProperties['Name']==='BetriebstatusActive')
                {
                  BetriebstatusActive = playerProperties['Value'];
                }
              }
            }
            if(player['Status'] == 'offline'){
              offlinePlayerArray1.push(player);
              if(Betriebsstatus=='Im Versand'){
                imVersandArray1.push(player);
              }
              if(BetriebstatusActive=='no'){
                offlinePlayerFilArray1.push(player);
                 let offlineSince = new Date(player['OfflineSince']);
                 let now = new Date();
                 now.setDate(now.getDate()-1);
                 if(offlineSince<=now){
                 
                  offlinePlayer24Array1.push(player);
                  //console.log(checkedPlayers['Players']);
                 
                 }
              }
            };
            if(player['Status'] == 'online'){
            
              onlinePlayerArray1.push(player);
            };
          });
        }
        else{
          var Betriebsstatus="";
          var BetriebstatusActive = "unknown";
          if(players['Properties'] !== undefined && players['Properties']['Property'] !== undefined){
            var playerProperties = players['Properties']['Property'];
            if(Array.isArray(playerProperties)){
              playerProperties.forEach((property)=>{
                if(property['Name']==='Betriebsstatus')
                {
                  Betriebsstatus = property['Value'];
                }
                if(property['Name']==='BetriebstatusActive')
                {
                  BetriebstatusActive = property['Value'];
                }
              });
            }
            else {
              if(playerProperties['Name']==='Betriebsstatus')
              {
                Betriebsstatus = playerProperties['Value'];
              }
              if(playerProperties['Name']==='BetriebstatusActive')
              {
                BetriebstatusActive = playerProperties['Value'];
              }
            }
          }
          if(players['Status'] == 'offline'){
            offlinePlayerArray1.push(players);
            if(Betriebsstatus=='Im Versand'){
              imVersandArray1.push(players);
            }
            if(BetriebstatusActive=='no'){
              offlinePlayerFilArray1.push(players);
              let offlineSince = new Date(players['OfflineSince']);
              let now = new Date();
              now.setDate(now.getDate()-1);
              if(offlineSince<=now){
               offlinePlayer24Array1.push(players);
              }
            }
           };
           if(players['Status'] == 'online'){
            onlinePlayerArray1.push(players);
           };
        }
      }
    });
    
   }
    setimVersandArray(imVersandArray1);
    setofflinePlayer24Array(offlinePlayer24Array1);
    setofflinePlayer24SArray(offlinePlayer24SArray1);
    setofflinePlayerArray(offlinePlayerArray1);
    setofflinePlayerFilArray(offlinePlayerFilArray1);
    setonlinePlayerArray(onlinePlayerArray1);
  }
  function getBetriebStatus(player){
   // console.log(player);
    var Betriebsstatus="";
    if(player['Properties'] !== undefined && player['Properties']['Property'] !== undefined){
      var playerProperties = player['Properties']['Property'];
      if(Array.isArray(playerProperties)){
        playerProperties.forEach((property)=>{
          if(property['Name']==='Betriebsstatus')
          {
            Betriebsstatus = property['Value'];
          }
        });
      }
      else {
        if(playerProperties['Name']==='Betriebsstatus')
        {
          Betriebsstatus = playerProperties['Value'];
        }
      }
    }
    return Betriebsstatus;
  }
  function getBetriebStatusActive(player){
    var Betriebsstatus="not set";
    if(player['Properties'] !== undefined && player['Properties']['Property'] !== undefined){
      var playerProperties = player['Properties']['Property'];
      if(Array.isArray(playerProperties)){
        playerProperties.forEach((property)=>{
          if(property['Name']==='BetriebstatusActive')
          {
            Betriebsstatus = property['Value'];
          }
        });
      }
      else {
        
        if(playerProperties['Name']==='BetriebstatusActive')
        {
          Betriebsstatus = playerProperties['Value'];
        }
      
      }
    }
    return Betriebsstatus;
  }
 function closeModel(row){
  // remove the last selected row
  console.log("selected Rows close model ", selectedRows);
  saveOrUpdate(row);
  console.log("row passed as parameter ", row);
  var param= selectedRows[0]['PlayerID'];
  deleteCheckedPlayers(param).then(()=>{
    checkedPlayers['Players'].forEach((ele)=>{
      if(ele['PlayerID']==param){
        setcheckedPlayers(checkedPlayers.Players.filter(function(ele){
          return ele !== ele['PlayerID']
        }))
      }

    })})
  
  console.log("selected Rows close model ", selectedRows);
  setShowModal(false);

 }
 const handleClearRows = () => {
  setToggleClearRows(!toggledClearRows);
}
function rowSelectChanged(selectableRows){
  
  var selectedRowsNew= selectableRows['selectedRows'];
  var selectedCountNew = selectableRows['selectedCount'];
  var selectedRowsCountOld = selectedRows.length;
  console.log("seletedRows new :",selectedRowsNew);
  console.log("SelectedCount New:",selectedCountNew);
  console.log("SelectedRows Old:", selectedRows);
  console.log("selectedRowsCount old:", selectedRowsCountOld);
  console.log("checked Players :", checkedPlayers);
  
  var lastSelectedElement=[];
  if(selectedCountNew>0 && selectedRowsCountOld<selectedCountNew){
    setSelectedRows(selectedRowsNew);
    setCurrentPlayer(selectedRowsNew[0]['PlayerID']);
    setShowModal(true);

  }
  if(selectedCountNew>0 && selectedRowsCountOld>selectedCountNew){
    const difference = selectedRows.filter(x => !selectedRowsNew.includes(x));
    console.log("Difference is ", difference);
    var param = difference[0]['PlayerID'];
    deleteCheckedPlayers(param).then(()=>{
      checkedPlayers['Players'].forEach((ele)=>{
        if(ele['PlayerID']==param){
          setcheckedPlayers(checkedPlayers.Players.filter(function(ele){
            return ele !== ele['PlayerID']
          }))
        }

      })}).then(()=>{
        setSelectedRows(selectedRowsNew);
      }).then(()=>{
        loadCheckedPlayers();
      })
  }
 
}
  
  function handleMessageSpeicher(e,param){
    e.preventDefault();
    setShowModal(false);
  }
  const rowSelectCritera = (row) => {

    //console.log("Row Critera playerId:----"+ row.PlayerID);
   
  var found = false;
  if(checkedPlayers['Players']!== undefined){
    checkedPlayers['Players'].forEach((elem)=>{
      
      if(elem['PlayerID']==row.PlayerID){
        found = true;
      }
    });
   
  
  }
  if(selectedRows.length>0){
    selectedRows.forEach((elem)=>{
    if(elem['PlayerID']==row.PlayerID){
      found = true;
    }

  });
}
    return found;
 }

  async function saveOrUpdate(e){
    await fetch('https://www.4dboards.de/php/setJsonBank.php?PlayerID='+currentPlayer+'&Message='+currentMessage
    ,{
      headers : { 
        'Accept': '*/*'
       },
       
    }
    )
      .then(function(response){
       
        return response.json();
      }).then((json)=>{
        console.log("Setting checkedPlayers from setbank:");
        console.log(json);
        setcheckedPlayers(json);
     //   console.log("save checked players");
      //  console.log(checkedPlayers['Players']);
      });

  }
  function convertArrayOfObjectsToCSV(array) {
    	let result;
    
    	const columnDelimiter = ',';
    	const lineDelimiter = '\n';
    	const keys = Object.keys(offlinePlayer24Array[0]);
    
    	result = '';
    	result += keys.join(columnDelimiter);
    	result += lineDelimiter;
    
    	array.forEach(item => {
    		let ctr = 0;
    		keys.forEach(key => {
    			if (ctr > 0) result += columnDelimiter;
    
    			result += item[key];
    		
    			ctr++;
    		});
    		result += lineDelimiter;
    	});
    
    	return result;
    }
    function downloadCSV(array) {
      console.log("array ", array );
      	const link = document.createElement('a');
      	let csv = convertArrayOfObjectsToCSV(array);
      	if (csv == null) return;
      
      	const filename = 'export.csv';
      
      	if (!csv.match(/^data:text\/csv/i)) {
      		csv = `data:text/csv;charset=utf-8,${csv}`;
      	}
      
      	link.setAttribute('href', encodeURI(csv));
      	link.setAttribute('download', filename);
      	link.click();
      }
      const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export to CSV</Button>;
  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(offlinePlayer24Array)} />, []);
  function handleClick(row,e){
    console.log(row);
    console.log(e);
  }
 
  //TODO::
  const conditionalRowStyles = [
    {
      
    	when: row => !selectedRows.includes(row),
    	style: {
    			backgroundColor: 'rgba(63, 195, 128, 0.8)',
    			color: 'white',
    			'&:hover': {
    				cursor: 'pointer',
    			},
    		},
    	},
    	{
    		when: row => selectedRows.includes(row),
    		style: {
    			backgroundColor: 'rgba(248, 148, 6, 0.9)',
    			color: 'white',
    			'&:hover': {
    				cursor: 'pointer',
    			},
    		},
    	}
    ];
  function handleMessage(event) {
    setCurrentMessage(event.target.value);
  }
  const notify = (place,PlayerID) => {
   console.log(PlayerID);
   console.log(checkedPlayers['Players']);
   console.log(checkedPlayers['Players'][PlayerID]);
   var message="";
   checkedPlayers['Players'].forEach((elem)=>{
    if(elem['PlayerID']==PlayerID){
      message=elem['Message'];
    }
  });



    var type = "primary";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Message : {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  if (loginState.isLoggedin || JSON.parse(window.localStorage.getItem('isLoggedin')) == 'true') {
    
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Offline Player Seit 24 </Card.Title>
                <p className="card-category">
                  Zeigt alle offline Player die Seit 24 Stunden offline sind und nicht eine Betriebstatus Haben
                </p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
              <DataTable columns={columns}
                         data={offlinePlayer24Array}
                         selectableRows
                         onSelectedRowsChange={rowSelectChanged}
                         conditionalRowStyles={conditionalRowStyles}
                         selectableRowSelected={rowSelectCritera}
                         onRowClicked = {handleClick}
                         actions={actionsMemo}
                         clearSelectedRows={toggledClearRows}
                        
                         />
              </Card.Body>
            </Card>
          </Col>
          
        </Row>
           {/* Mini Modal */}
           <Modal
          className="modal-mini modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
          <form>
          <label>
          Message:
          <input type="text" name="Message" id="modal-message" value={currentMessage} onChange={handleMessage} />
          <input type="hidden" name="PlayerID" id="modal-playerid" value={currentPlayer}/>
          </label>
         
          </form>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={(e) =>{ 
                saveOrUpdate(e);
                setShowModal(false);
              }}
            >
              Speichern
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={closeModel}
            >
              Schließen
            </Button>
            
          </div>
        </Modal>
        {/* End Modal */}
      </Container>
    </>
  );
            }
  else{
    return (
      <Container fluid>
       <form onSubmit={handleLogin}>
      <label>Enter password:
        <input type="password" autocomplete="current-password" value={loginState.passIt}
          onChange={(e) => setLoginState({isLoggedin:false,passIt:e.target.value})}/>
      </label>
      <input type="submit" />
    </form>
    </Container>
     )
  }
}
export default OffList;
