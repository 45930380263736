import React from "react";
import ChartistGraph from "react-chartist";
import {useEffect, useState, useRef} from 'react';
import ReactModalLogin from "react-modal-login";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { get } from "jquery";

function Overview({loginStatep}) {
  const [PlayerData, setPlayerData] = useState({dataArray:{}});
  const [offlineCount,setofflineCount] = useState(0);
  const [offlineCountFilterd,setofflineCountFilterd] = useState(0);
  const [onlineCount,setonlineCount] = useState(0);
  const [offlineSeit24,setofflineSeit24]= useState(0);
  const [offlineSeit24S,setofflineSeit24S]= useState(0);
  const [imVersand,setimVersand]= useState(0);
  const [loginState,setLoginState]= useState(loginStatep);
  const [checkedPlayers,setcheckedPlayers] = useState(Array());
  const handleLogin = (event) => {
    event.preventDefault();
    console.log("handle login is called");
    if(loginState.passIt=="hpnx1337"){
      setLoginState({
        isLoggedin:true,
        
      });
      loginStatep.isLoggedin = true;
    
      window.localStorage.setItem('isLoggedin', 'true');

    }
    

  }
  const getData= async ()=>{
    await fetch('./data/PlayerData.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'mode': '*cors',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
       }
    }
    )
      .then(function(response){
       
        return response.json();
      }).then((json)=>{
        setPlayerData(json);
      });
  }
  
  useEffect(()=>{
    getData();
  },[]);
  useEffect(()=>{
    countOfflinePlayer();
    loadCheckedPlayers();
  },[PlayerData]);
  async function loadCheckedPlayers(){
    await fetch('https://www.4dboards.de/php/getJsonBank.json'
    ,{
      headers : { 
        'Accept': '*/*',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Access-Control-Allow-Origin': '*',
        'Pragma': 'no-cache',
        'Expires': 0,
        'mode': '*cors',
      
       },
    }
    )
      .then(function(response){
       
        return response.json();
      }).then((json)=>{
        setcheckedPlayers(json);
//        console.log("checked players");
 //       console.log(checkedPlayers['Players']);
      });
  }
  var offlineCount1 = 0;
  var offlineCountFilterd1 = 0;
  var onlineCount1 = 0;
  var offlineSeit241 = 0;
  var offlineSeit24S1 = 0;
  var imVersand1 = 0;
  async function  countOfflinePlayer () {
    if(Array.isArray(PlayerData['dataArray']) & PlayerData['dataArray']!== null){
    var customersMix = PlayerData['dataArray'];
    customersMix.forEach(function(customer){
      var playersArray = customer['Players'];
      if(playersArray['Player'] !== undefined){
        var players= playersArray['Player'];
        if(Array.isArray(players)){
          players.forEach((player)=>{
            var Betriebsstatus="";
            var BetriebstatusActive = "unknown";
            if(player['Properties'] !== undefined && player['Properties']['Property'] !== undefined){
              var playerProperties = player['Properties']['Property'];
              if(Array.isArray(playerProperties)){
                playerProperties.forEach((property)=>{
                  if(property['Name']==='Betriebsstatus')
                  {
                    Betriebsstatus = property['Value'];
                  }
                  if(property['Name']==='BetriebstatusActive')
                  {
                    BetriebstatusActive = property['Value'];
                  }
                });
              }
              else { 
                if(playerProperties['Name']==='Betriebsstatus')
                {
                  Betriebsstatus = playerProperties['Value'];
                }
                if(playerProperties['Name']==='BetriebstatusActive')
                {
                  BetriebstatusActive = playerProperties['Value'];
                }
              }
            }
            if(player['Status'] == 'offline'){
              offlineCount1++;
              if(Betriebsstatus=='Im Versand'){
                imVersand1++;
              }
              if(BetriebstatusActive=='no'){
                offlineCountFilterd1++;
                 let offlineSince = new Date(player['OfflineSince']);
                 let now = new Date();
                 now.setDate(now.getDate()-1);
                 if(offlineSince<=now){
                  offlineSeit241++;
                  var foundCheck = false;
                  if(checkedPlayers['Players']!==undefined){
                  checkedPlayers['Players'].forEach((pl) => {
                    if(pl.PlayerID == player['PlayerID']) {
                      console.log("found player:"+pl.PlayerID);
                      foundCheck = true;
                    }
                  });}
                  if(!foundCheck)
                    offlineSeit24S1++; 
                 }
                 
              }
            };
            if(player['Status'] == 'online'){
              onlineCount1++;
            };
          });
        }
        else{
          var Betriebsstatus="";
          var BetriebstatusActive = "no";
          if(players['Properties'] !== undefined && players['Properties']['Property'] !== undefined){
            var playerProperties = players['Properties']['Property'];
            if(Array.isArray(playerProperties)){
              playerProperties.forEach((property)=>{
                if(property['Name']==='Betriebsstatus')
                {
                  Betriebsstatus = property['Value'];
                }
                if(property['Name']==='BetriebstatusActive')
                {
                  BetriebstatusActive = property['Value'];
                }
              });
            }
            else {
              if(playerProperties['Name']==='Betriebsstatus')
              {
                Betriebsstatus = playerProperties['Value'];
              }
              if(playerProperties['Name']==='BetriebstatusActive')
              {
                BetriebstatusActive = playerProperties['Value'];
              }
            }
          }
          if(players['Status'] == 'offline'){
            offlineCount1++;
            if(Betriebsstatus=='Im Versand'){
              imVersand1++;
            }
            if(BetriebstatusActive=='no'){
              offlineCountFilterd1++;
              let offlineSince = new Date(players['OfflineSince']);
              let now = new Date();
              now.setDate(now.getDate()-1);
              if(offlineSince<=now){
               offlineSeit241++;  
               var foundCheck = false;
                  if(checkedPlayers['Players']!==undefined){
                    
                  checkedPlayers['Players'].forEach((pl) => {
                    if(pl.PlayerID == players['PlayerID']) {
                      console.log("found player:"+pl.PlayerID);
                      foundCheck = true;
                    }
                  });
                }
                if(!foundCheck)  
                  offlineSeit24S1++;    
              }
            }
           };
           if(players['Status'] == 'online'){
            onlineCount1++;
           };
        }
      }
    });
    }
    setofflineCount(offlineCount1);
    setonlineCount(onlineCount1);
    setofflineSeit24(offlineSeit241);
    setofflineSeit24S(offlineSeit24S1);
    setofflineCountFilterd(offlineCountFilterd1);
    setimVersand(imVersand1);
    }

    function openModal() {
      setLoginState(
        {showModal:true,loading:false,error:null, isLoggedin:false}
      );
    }
  
    function closeModal() {
      setLoginState({showModal:false,loading:false,error:null, isLoggedin:true});
    }
  
    function onLoginSuccess(method, response) {
      setLoginState({showModal:false,loading:false,error:null, isLoggedin:true})
      console.log("logged successfully with " + method);
    }
  
    function onLoginFail(method, response) {
      console.log("logging failed with " + method);
      setLoginState({showModal:true,loading:false,error:null, isLoggedin:false});
    }
  
    function startLoading() {
      setLoginState({showModal:true,loading:true,error:null, isLoggedin:false});
    }
  
    function finishLoading() {
      setLoginState({showModal:true,loading:false,error:null, isLoggedin:false});
    }
  
    function afterTabsChange() {
      setLoginState({
        error: null
      });
    }

    if (loginStatep.isLoggedin == true ) {
      return (
        <>
          <Container fluid>
          <Row>
              
              <Col md="4">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Betriebstatus</Card.Title>
                    <p className="card-category">Tages Performace</p>
                  </Card.Header>
                  <Card.Body>
                    <div
                      className="ct-chart ct-perfect-fourth"
                      id=""
                    >
                      <ChartistGraph
                        data={{
                          labels: [`${onlineCount}`, `${offlineSeit24S}`],
                          series: [{value: onlineCount, className: 'dgreen'}, {value: offlineSeit24, className: 'dred'}],
                        }}
                        type="Pie"
                      />
                    </div>
                    <div className="legend">
                      <i className="fas fa-circle text-success"></i>
                      Online <i className="fas fa-circle text-danger"></i>
                      Offline 
                    </div>
                    <hr></hr>
                    <div className="stats">
                      <i className="far fa-clock"></i>
                      Aktualisiert am {}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">2022 Online Performace</Card.Title>
                    <p className="card-category"></p>
                  </Card.Header>
                  <Card.Body>
                    <div className="ct-chart" id="chartActivity">
                      <ChartistGraph
                      /* TODO:: Hier echte data aus Datenbank 
                      moementan fake werte */
                        data={{
                          labels: [
            
                            "Oct",
                            "Nov",
                            "Dec",
                          ],
                          series: [
                            [
                            
                              610,
                              756,
                              895,
                            ],
                            [
                              
                              410,
                              636,
                              695,
                            ],
                          ],
                        }}
                        type="Bar"
                        options={{
                          seriesBarDistance: 10,
                          axisX: {
                            showGrid: false,
                          },
                          height: "245px",
                        }}
                        responsiveOptions={[
                          [
                            "screen and (max-width: 640px)",
                            {
                              seriesBarDistance: 5,
                              axisX: {
                                labelInterpolationFnc: function (value) {
                                  return value[0];
                                },
                              },
                            },
                          ],
                        ]}
                      />
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <div className="legend">
                      <i className="fas fa-circle text-info"></i>
                      Online <i className="fas fa-circle text-danger"></i>
                      Offline
                    </div>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-check"></i>
                      Data ist partial correct
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row>
      
              <Col lg="3" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-bulb-63 text-success"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Online Player</p>
                          <Card.Title as="h4" className="text-success">{onlineCount}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="far nc-watch-time mr-1"></i>
                      Aktualisiert am: {}
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="3" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-support-17 text-danger"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Offline Player</p>
                          <Card.Title as="h4" className="text-danger">{offlineSeit24S}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="far fa-clock-o mr-1"></i>
                      mehr als 24 Stunden
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="3" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-cctv text-warning"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Kurzeitig Offline Gemeldte Player</p>
                          <Card.Title as="h4" className="text-warning">{offlineCountFilterd-offlineSeit24}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-redo mr-1"></i>
                      Aktualisiert am: {}
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="3" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-globe-2 text-primary"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Gesamt Anzahl</p>
                          <Card.Title as="h4" className="text-primary">{onlineCount+offlineSeit24+(offlineCountFilterd-offlineSeit24)}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-redo mr-1"></i>
                      Aktualisieren
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="3" sm="7">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-settings-tool-66 text-info"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Player in vorbereitung</p>
                          <Card.Title as="h4" className="text-info">{imVersand}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-redo mr-1"></i>
                      Aktualisieren
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="3" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-globe-2 text-info" style={{Color:'red'}}></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Anzahle Jemals Angemeldete Player</p>
                          <Card.Title as="h4" className="text-info">{onlineCount+offlineCount}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-redo mr-1"></i>
                      Aktualisieren
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
        
            
          </Container>
        </>
      );
                      }
    else{
     return (
      <Container fluid>
       <form onSubmit={handleLogin}>
      <label>Enter password:
        <input type="password" value={loginState.passIt}
          onChange={(e) => setLoginState({isLoggedin:false,passIt:e.target.value})}/>
      </label>
      <input type="submit" />
    </form>
    </Container>
     )
    }
}

export default Overview;
