/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import {useEffect, useState} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import OffList from "views/OffList";
import FilterList from "views/FilterList";
import AllOfflineList from "views/AllOfflineList";
import Overview from "views/Overview";
import CurrentList from "views/CurrentList";
import BetriebList from "views/BetriebList";
import KadeweList from "views/KadeWeList";
import TBCList from "views/TBCList";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
  <BrowserRouter>
    <Switch>
    
    <Route exact path="/">{<Redirect to="/overview"/> }</Route>
      <Route path="/"  render={(props) => <AdminLayout {...props} />} />
      <Route path="/overview"  render= {(props) => <Overview {...props} />} />
      <Route path="/offline"  render={(props) => <OffList {...props}  />} />
      <Route path="/playerFilter"  render={(props) => <FilterList {...props}  />} />
      <Route path="/allePlayer"  render={(props) => <AllOfflineList {...props} />} />
      <Route path="/playerBetrieb" render={(props) => <BetriebList {...props}  />} />
      <Route path="/kadewe"  render={(props) => <KadeweList {...props}  />} />
      <Route path="/tbc"  render={(props) => <TBCList {...props} />}   />
    </Switch>
  </BrowserRouter>
);
