/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import OffList from "views/OffList.js";
import Overview from "views/Overview.js";
import AllOfflineList from "views/AllOfflineList";
import FilterList from "views/FilterList";
import CurrentList from "views/CurrentList";
import BetriebList from "views/BetriebList";
import KadeweList from "views/KadeWeList";
import TBCList from "views/TBCList";
import Notifications from "views/Notifications";
import LoginAuth from "views/LoginAuth";

const dashboardRoutes = [
  
  {
    path: "/overview",
    name: "Overview",
    icon: "nc-icon nc-chart-pie-35",
    component: Overview,
    layout: ""
  },
    {
    path: "/offline",
    name: "Offline Seit 24",
    icon: "nc-icon nc-notes",
    component: OffList,
    layout: ""
  },
  {
    path: "/playerFilter",
    name: "Gefilterte Player",
    icon: "nc-icon nc-notes",
    component: FilterList,
    layout: ""
  },
  {
    path: "/allePlayer",
    name: "Alle Offline Player",
    icon: "nc-icon nc-notes",
    component: AllOfflineList,
    layout: ""
  },
  {
    path: "/playerBetrieb",
    name: "Betriebstatus Player",
    icon: "nc-icon nc-notes",
    component: BetriebList,
    layout: ""
  },
  {
    path: "/kadewe",
    name: "Kadewe Monitoring",
    icon: "nc-icon nc-notes",
    component: KadeweList,
    layout: ""
  },
  {
    path: "/tbc",
    name: "TBC List",
    icon: "nc-icon nc-notes",
    component: TBCList,
    layout: ""
  }
  
];

export default dashboardRoutes;
