import React from "react";
import {useEffect, useState} from 'react';
import Overview from './Overview';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
//var PlayerData= require ("../data/PlayerData.json");
// react-bootstrap components

import {
  ReactTable,
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form
} from "react-bootstrap";

function LoginAuth() {
  
  const [loginState,setLoginState]= useState({isLoggedin:false,passIt:""});
  const handleLogin = (event) => {
    event.preventDefault();
    if(loginState.passIt=="hpnx1337"){
      setLoginState({
        isLoggedin:true,
      
      });
    }
    

  }
  
 
  
  if (!loginState.isLoggedin) {
  return (
    <>
  <Container fluid>
       <form onSubmit={handleLogin}>
      <label>Enter password:
        <input type="password" value={loginState.passIt}
          onChange={(e) => setLoginState({isLoggedin:false,passIt:e.target.value})}/>
      </label>
      <input type="submit" />
    </form>
    </Container>
    </>
  );}
  else{
    return (
      <>
        {/* This is the alias of BrowserRouter i.e. Router */}
        <BrowserRouter>
          <Switch>
          
             <Route exact path="/">{<Redirect to="/overview"/> }</Route>
             <Route exact path="/overview" >{<Redirect to="/overview"/> }</Route>
             <Route exact path="/offline" >{<Redirect to="/offline" /> }</Route>
             <Route path="/playerFilter"  render={(props) => <FilterList {...props} />} />
             <Route path="/allePlayer"  render={(props) => <AllOfflineList {...props} />} />
            <Route path="/playerBetrieb" render={(props) => <BetriebList {...props} />} />
            <Route path="/kadewe"  render={(props) => <KadeweList {...props} />} />
             <Route path="/tbc"  render={(props) => <TBCList {...props} />} />
            {/* If any route mismatches the upper 
            route endpoints then, redirect triggers 
            and redirects app to home component with to="/" 
            <Redirect to="/" />*/}
          </Switch>
        </BrowserRouter>
        </>
        )
  }
}
export default LoginAuth;
