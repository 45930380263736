import React from "react";
import {useEffect, useState} from 'react';

// react-bootstrap components
import {
  ReactTable,
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form
} from "react-bootstrap";

function KadeweList() {
  const [PlayerData, setPlayerData] = useState({Players:[]});
  const [onlinePlayerArray,setonlinePlayerArray] = useState(Array());
  const [offlinePlayer24Array,setofflinePlayer24Array] = useState(Array());
  const [offlinePlayerArray,setofflinePlayerArray] = useState(Array());
  const [offlinePlayerFilArray,setofflinePlayerFilArray]= useState(Array());
  const [imVersandArray,setimVersandArray]= useState(Array());
  const [loginState,setLoginState]= useState({isLoggedin:false,passIt:""});
  const handleLogin = (event) => {
    event.preventDefault();
    if(loginState.passIt=="hpnx1337"){
      setLoginState({
        isLoggedin:true,
      
      });
    }
    

  }
  const getData= async ()=>{
    await fetch('https://apimiddleware.azurewebsites.net/Monitor/monIndex.php'
    ,{
      headers : { 
        'Accept': 'application/json',
       }
    }
    )
      .then(function(response){
        console.log(response);
        return response.json();
      }).then((json)=>{
        console.log(json);
        setPlayerData(json);
        setofflinePlayerArray(json['Players']);
      });
  }
  var offlinePlayerArray1=Array();
  useEffect(()=>{
    getData();
  },[]);
  useEffect(()=>{
    offlinePlayerArray1=PlayerData['Players'];
    setofflinePlayerArray(offlinePlayerArray1);
  },[PlayerData]);
  function handleCheckClick(e, param) {
    //e.preventDefault();
    console.log('Event',e);
    console.log('param',param);
    if(e.target.checked){
      console.log("Got True");
    }
    else{
      console.log("Got False");
    }
  }
  function ErrorString(level){
   const intLevel = parseInt(level,10);
   if(isNaN(intLevel)){
    return "NaN";
   }
   if(intLevel==0){
    return "Online";
   }
   if(intLevel==1){
    return "Offline";
   }
   if(intLevel==2){
    return "Download File Error";
   }
   if(intLevel==3){
    return "Other Log Error";
   }
   if(intLevel==4){
    return "Could not Check Logs";
   }
  }
  function getErrorColor(level){
    const intLevel = parseInt(level,10);
    if(isNaN(intLevel)){
     return "yellow";
    }
    if(intLevel==0){
     return "#F0F8FF";
    }
    if(intLevel==1){
     return "#FF7F50";
    }
    if(intLevel==2){
     return "#FF0000";
    }
    if(intLevel==3){
     return "yellow";
    }
    if(intLevel==4){
     return "yellow";
    }
   }
   if (loginState.isLoggedin) {
  return (
    <>
      <Container fluid>
        <Row>
       
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h4">Alle Offline Player</Card.Title>
                <p className="card-category">
                  Alle Offline Player mit Ihre Betriebsstatus
                </p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                    <th className="border-0">Customer ID</th>
                      <th className="border-0">PlayerID</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">Error Level</th>
                      <th className="border-0">Media File</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log("______________________")}
                    {console.log(offlinePlayerArray)}
                    {console.log("______________________")}
                  {
                      offlinePlayerArray.map(player=>(
                        
                        <tr style={{background:getErrorColor(player['Level'])}}>
                        <td>{player['CustomerID']}</td>
                        <td>{player['PlayerID']}</td>
                        <td>{player['Name']}</td>
                        <td>{ErrorString(player['Level'])}</td>
                        
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={"ttMark-"+player['PlayerID']}>
                                Markieren
                              </Tooltip>
                            }
                          >
                            <Button
                              className="btn-simple btn-link p-1"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-heart"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={"ttRemove-"+player['PlayerID']}>UnMarkieren</Tooltip>
                            }
                          >
                            <Button
                              className="btn-simple btn-link p-1"
                              type="button"
                              variant="danger"
                              
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                        </tr>

                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
       
      </Container>
    </>
  );
                          } else{
                            return (
                              <Container fluid>
                               <form onSubmit={handleLogin}>
                              <label>Enter password:
                                <input type="password" value={loginState.passIt}
                                  onChange={(e) => setLoginState({isLoggedin:false,passIt:e.target.value})}/>
                              </label>
                              <input type="submit" />
                            </form>
                            </Container>
                             )
                          }
}

export default KadeweList;
