import React from "react";
import {useEffect, useState} from 'react';

// react-bootstrap components
import {
  ReactTable,
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Form
} from "react-bootstrap";

function AllOfflineList() {
  const [PlayerData, setPlayerData] = useState({dataArray:{}});
  const [onlinePlayerArray,setonlinePlayerArray] = useState(Array());
  const [offlinePlayer24Array,setofflinePlayer24Array] = useState(Array());
  const [offlinePlayerArray,setofflinePlayerArray] = useState(Array());
  const [offlinePlayerFilArray,setofflinePlayerFilArray]= useState(Array());
  const [imVersandArray,setimVersandArray]= useState(Array());
  const [loginState,setLoginState]= useState({isLoggedin:false,passIt:""});
  const handleLogin = (event) => {
    event.preventDefault();
    console.log("handle login is called");
    if(loginState.passIt=="hpnx1337"){
      setLoginState({
        isLoggedin:true,
      });
    
    
      window.localStorage.setItem('isLoggedin', 'true');
    }
  }
  const getData= async ()=>{
    fetch('./data/PlayerData.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'mode': '*cors',
       }
    }
    )
      .then(function(response){
        return response.json();
      }).then((json)=>{
        setPlayerData(json);
      });
  }
  useEffect(()=>{
    getData();
  },[]);
  useEffect(()=>{
    loadPlayers();
  },[PlayerData]);

var offlinePlayer24Array1=Array();
var offlinePlayerArray1=Array();
var onlinePlayerArray1= Array();
var imVersandArray1= Array();
var offlinePlayerFilArray1= Array();
 async function loadPlayers(){
    offlinePlayer24Array1=[]; 
    offlinePlayerFilArray1=[];
    offlinePlayerArray1=[];
    if(Array.isArray(PlayerData['dataArray']) & PlayerData['dataArray']!== null){
    var customersMix = PlayerData['dataArray'];
    customersMix.forEach(function(customer){
      var playersArray = customer['Players'];
      if(playersArray['Player'] !== undefined){
        var players= playersArray['Player'];
        if(Array.isArray(players)){
          players.forEach((player)=>{
            var Betriebsstatus="";
            var BetriebstatusActive = "unknown";
            if(player['Properties'] !== undefined && player['Properties']['Property'] !== undefined){
              var playerProperties = player['Properties']['Property'];
              if(Array.isArray(playerProperties)){
                playerProperties.forEach((property)=>{
                  if(property['Name']==='Betriebsstatus')
                  {
                    Betriebsstatus = property['Value'];
                  }
                  if(property['Name']==='BetriebstatusActive')
                  {
                    BetriebstatusActive = property['Value'];
                  }
                });
              }
              else {
                
                if(playerProperties['Name']==='Betriebsstatus')
                {
                  Betriebsstatus = playerProperties['Value'];
                }
                if(playerProperties['Name']==='BetriebstatusActive')
                {
                  BetriebstatusActive = playerProperties['Value'];
                }
              }
            }
            if(player['Status'] == 'offline'){         
              offlinePlayerArray1.push(player);
              if(Betriebsstatus=='Im Versand'){
                imVersandArray1.push(player);
              }
              if(BetriebstatusActive=='no'){
                offlinePlayerFilArray1.push(player);
                 let offlineSince = new Date(player['OfflineSince']);
                 let now = new Date();
                 now.setDate(now.getDate()-1);
                 if(offlineSince<=now){
                  offlinePlayer24Array1.push(player);
                 }
              }
            };
            if(player['Status'] == 'online'){
              onlinePlayerArray1.push(player);
            };
          });
        }
        else{
          var Betriebsstatus="";
          var BetriebstatusActive = "unknown";
          if(players['Properties'] !== undefined && players['Properties']['Property'] !== undefined){
            var playerProperties = players['Properties']['Property'];
            if(Array.isArray(playerProperties)){
              playerProperties.forEach((property)=>{
                if(property['Name']==='Betriebsstatus')
                {
                  Betriebsstatus = property['Value'];
                }
                if(property['Name']==='BetriebstatusActive')
                {
                  BetriebstatusActive = property['Value'];
                }
              });
            }
            else {
              if(playerProperties['Name']==='Betriebsstatus')
              {
                Betriebsstatus = playerProperties['Value'];
              }
              if(playerProperties['Name']==='BetriebstatusActive')
              {
                BetriebstatusActive = playerProperties['Value'];
              }
            }
          }
          if(players['Status'] == 'offline'){
            offlinePlayerArray1.push(players);
            if(Betriebsstatus=='Im Versand'){
              imVersandArray1.push(players);
            }
            if(BetriebstatusActive=='no'){
              offlinePlayerFilArray1.push(players);
              let offlineSince = new Date(players['OfflineSince']);
              let now = new Date();
              now.setDate(now.getDate()-1);
              if(offlineSince<=now){
               offlinePlayer24Array1.push(players);
    
              }
            }
           };
           if(players['Status'] == 'online'){
            onlinePlayerArray1.push(players);
           };
        }
      }
    });
    
   }
    setimVersandArray(imVersandArray1);
    setofflinePlayer24Array(offlinePlayer24Array1);
    console.log(offlinePlayer24Array1);
    setofflinePlayerArray(offlinePlayerArray1);
    setofflinePlayerFilArray(offlinePlayerFilArray1);
    setonlinePlayerArray(onlinePlayerArray1);
  }

  function getBetriebStatus(player){
    var Betriebsstatus="";
    if(player['Properties'] !== undefined && player['Properties']['Property'] !== undefined){
      var playerProperties = player['Properties']['Property'];
      if(Array.isArray(playerProperties)){
        playerProperties.forEach((property)=>{
          if(property['Name']==='Betriebsstatus')
          {
            Betriebsstatus = property['Value'];
          }
        });
      }
      else {
        
        if(playerProperties['Name']==='Betriebsstatus')
        {
          Betriebsstatus = playerProperties['Value'];
        }
      
      }
    }
    return Betriebsstatus;
  }
  function getBetriebStatusActive(player){
    var Betriebsstatus="not set";
    if(player['Properties'] !== undefined && player['Properties']['Property'] !== undefined){
      var playerProperties = player['Properties']['Property'];
      if(Array.isArray(playerProperties)){
        playerProperties.forEach((property)=>{
          if(property['Name']==='BetriebstatusActive')
          {
            Betriebsstatus = property['Value'];
          }
        });
      }
      else {
        
        if(playerProperties['Name']==='BetriebstatusActive')
        {
          Betriebsstatus = playerProperties['Value'];
        }
      
      }
    }
    return Betriebsstatus;
  }
  function handleCheckClick(e, param) {
    //e.preventDefault();
    console.log('Event',e);
    console.log('param',param);
    if(e.target.checked){
      console.log("Got True");
    }
    else{
      console.log("Got False");
    }
    
  }
  if (loginState.isLoggedin) {
  return (
    <>
      <Container fluid>
        <Row>
       
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h4">Alle Offline Player</Card.Title>
                <p className="card-category">
                  Alle Offline Player mit Ihre Betriebsstatus
                </p>
              </Card.Header>
              
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr key="header123">
                    <th className="border-0">Player ID</th>
                    <th className="border-0">Customer ID</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">BetriebsstatusActive</th>
                      <th className="border-0">Betriebsstatus</th>
                      <th className="border-0">offline Seit</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                      
                      offlinePlayerArray.map(player=>(
                        
                      console.log("player for:"+player['PlayerID']),
                      console.log("player offline:"+JSON.stringify(player['OfflineSince'])),
                        <tr id={"row-"+player['PlayerID']} key={"row-"+player['PlayerID']}>
                        <td>{player['PlayerID']}</td>
                        <td>{player['CustomerID']}</td>
                        <td>{player['Name']}</td>
                        <td>{getBetriebStatusActive(player)}</td>
                        <td>{getBetriebStatus(player)}</td>
                        
                        <td key={"tt-"+player['PlayerID']}>{JSON.stringify(player['OfflineSince'])}</td>
                        <td className="td-actions text-right" key={"otd-"+player['PlayerID']}>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={"ottMark-"+player['PlayerID']}>
                                Markieren
                              </Tooltip>
                            }
                          >
                            <Button
                              className="btn-simple btn-link p-1"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-heart"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={"ttRemove-"+player['PlayerID']}>UnMarkieren</Tooltip>
                            }
                          >
                            <Button
                              className="btn-simple btn-link p-1"
                              type="button"
                              variant="danger"
                              
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                 
                        </tr>
                      
                      )
                      )
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
       
      </Container>
    </>
  );
                          }
  else{
    return (
      <Container fluid>
       <form onSubmit={handleLogin}>
      <label>Enter password:
        <input type="password" value={loginState.passIt}
          onChange={(e) => setLoginState({isLoggedin:false,passIt:e.target.value})}/>
      </label>
      <input type="submit" />
    </form>
    </Container>
     )
  }
}

export default AllOfflineList;
